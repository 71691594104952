import { useVisitorSplits } from 'src/split-hooks';

export function usePlansPageDescriptionSplit() {
  const visitorSplits = useVisitorSplits(['PUG_Plans_Page_Description']);

  return {
    isInRemovalTreatment: visitorSplits.PUG_Plans_Page_Description === 'v1',
    isInNewDescriptionTreatment:
      visitorSplits.PUG_Plans_Page_Description === 'v2',
    plansPageDescriptionExperimentReady: visitorSplits.isReady,
  };
}
